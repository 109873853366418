import request from '../utils/request';

// 点赞取消点赞
export const wzaskslike = (targetId,type) => request({
  url: '/api/blade-asks/wzaskslike/submit',
  method: 'post',
  data: {
    targetId,
    type
  },
});

// 我的点赞
export const getMyLikes = (current,size,type) => request({
  url: '/api/blade-asks/wzaskslike/mylikes',
  method: 'get',
  params: {
    current,size,type,descs:'create_time',
    targetCategory: 101
  },
});

