import request from '../utils/request';

// 获取提问评论分页
export const getCommentsList = (current,size,parentId,typeId) => request({
  url: '/api/blade-asks/wzaskscomments/front/page',
  method: 'get',
  params: {
    current,
    size,
    parentId,
    typeId
  },
});


// 获取提问评论列表
export const getWzaskscomments = (targetId,typeId) => request({
  url: '/api/blade-asks/wzaskscomments/front/page',
  method: 'get',
  params: {
    current:1,
    size:20,
    targetId,
    typeId
  },
});

// 新增评论
export const addComments = (content,targetId,parentId,typeId) => request({
  url: '/api/blade-asks/wzaskscomments/front',
  method: 'POST',
  data: {
    content,
    isPublic:0,
    parentId,
    targetId,
    typeId
  },
});

// 我的回答
export const getMyComments = (current,size,typeId) => request({
  url: '/api/blade-asks/wzaskscomments/mycomments',
  method: 'get',
  showLoading:true,
  params: {
    current,size,typeId,descs:'create_time',
    targetCategory: 101
  },
});

// 智库详情
export const getZkDetail = (id) => request({
  url: `/api/blade-asks/wzaskscomments/front/${id}`,
  method: 'get',
  showLoading:true,
});